<template>
  <v-app>
    <div class="row">
      <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Additional Assessment of {{`${course.title} (${course.grade_title})`}}</h4>

                </div>
                <div class="breadcrumb-right">
                  <v-btn v-if="program && program.additional_assessments"
                         @click="openDialog()"
                         class="mt-4 btn btn-primary  mr-1"
                         dark>
                    <i class="fa fa-plus"></i>
                    Add assessment
                  </v-btn>

                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <div class="col-md-6">
                    <v-select
                        class="form-control"
                        outlined  v-on:keyup.enter="getAdditionalAssessments()"
                        v-model="search.academic_year"
                        dense clearable
                        :items="academicYears"
                        item-text="title"
                        item-value="id"
                        label="Batch"
                    >
                    </v-select>
                  </div>
                  <div class="col-3">
                    <v-select label="Status" outlined dense :items="active_statuses"
                              item-text="title" v-on:keyup.enter="getAdditionalAssessments()"
                              item-value="value" clearable
                              v-model="search.active"></v-select>
                  </div>

                  <v-col cols="3" md="3" class="text-right">
                    <v-btn
                        @click="getAdditionalAssessments()"
                        class="btn btn-primary btn-search "
                        :loading="loading"
                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div class="col-12" v-if="program && !program.additional_assessments">
                <v-alert type="error">Please set an additional assessment in program</v-alert>
              </div>
              <div class="">
                <table class="table">
                  <thead>
                    <tr class="px-3">
                      <th>Title</th>
                      <th>Mark</th>
                      <th>Pass mark</th>
                      <th>Academic year</th>
                      <th>Impacts result</th>
                      <th class="pr-3 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="additionalAssessments.length>0">
                  <tr v-for="(additionalAssessment, index) of additionalAssessments" :key="index">
                    <td>
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1" href="#" @click.prevent="editAdditionalAssessments(additionalAssessment)">{{ additionalAssessment.title }}</a>&nbsp;&nbsp;
                      <i class="fas fa-circle ml-2"
                         :class="additionalAssessment.is_active?'dot-active':'dot-inactive'"></i>
                    </td>
                    <td> <span class="font-weight-medium">{{ additionalAssessment.mark }}</span></td>
                    <td> <span class="font-weight-medium">{{ additionalAssessment.pass_mark }}</span></td>
                    <td>
                      <span class="font-weight-medium">{{additionalAssessment.academic_year_title}}
                      </span>
                    </td>
                    <td> <span class="font-weight-medium">{{ additionalAssessment.impacts_result?'Yes':'No' }}</span></td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item"  >
                              <a class="navi-link" @click="editAdditionalAssessments(additionalAssessment)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteAssessment(additionalAssessment.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                    <td class="px-3 text-center" colspan="6">No additional assessment added yet</td>
                  </tr>
                  </tbody>
                </table>
              </div>
<!--              <div
                  class="wizard wizard-2"
                  id="kt_wizard_v2"
                  data-wizard-state="step-first"
                  data-wizard-clickable="true"
              >
                <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                  <div class="row">

                    &lt;!&ndash;              <pre>{{course}}</pre>&ndash;&gt;



                    <form @submit.prevent="getAdditionalAssessments()" class="row mt-5 mb-5 bg-filter">

                      <div class="col-md-4">
                        <v-select
                            class="form-control"
                            outlined
                            v-model="search.academic_year"
                            dense
                            :items="academicYears"
                            item-text="title"
                            item-value="id"
                            label="Batch"
                        >
                        </v-select>
                      </div>
                      <div class="col-4">
                        <v-select label="Status" outlined dense :items="active_statuses"
                                  item-text="title"
                                  item-value="value"
                                  v-model="search.active"></v-select>
                      </div>
                      <div class="col-4">
                        <v-btn depressed class="btn btn-primary text-white" @click="getAdditionalAssessments">Search</v-btn>
                        <v-btn depressed class="btn btn-standard ml-2" @click="resetFilter">Reset</v-btn>
                      </div>
                    </form>

                  </div>

                  <div class="row">
                    <div class="col-12">



                    </div>
                  </div>
                </div>
              </div>-->

            </div>
            <create-and-update v-if="program && program.additional_assessments"
                         ref="additional-assessment"
                         :course_id="course_id"
                         :program="program"
                         @refresh="getAdditionalAssessments"
      ></create-and-update>
          </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CreateAndUpdate from "./CreateAndUpdate";
import AdditionalAssessment from "@/core/services/additionalAssessment/AdditionalAssessmentService";
import ProgramService from "@/core/services/level/program/ProgramService";
import CoursesService from "@/core/services/courses/CoursesService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
// import
const programService = new ProgramService();
const additionalAssessment = new AdditionalAssessment();
const courseService = new CoursesService();
const academicYearService = new AcademicYearService();

export default {
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      additionalAssessments: [],
      course: {},
      search: {},
      program: {},
      dialog: false,
      loading: false,
      academicYears:[],
      active_statuses: [
        {title: 'Active', value: "active"},
        {title: 'Inactive', value: "in_active"}
      ],
    };
  },
  mounted() {
    this.$tabs.refresh()
    this.getAcademicYears()
    this.getAdditionalAssessments();
    this.getCourse()
    // this.getProgram();
  },
  computed: {
    course_id() {
      return this.$route.params.id
    }
  },
  methods: {
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;
      });
    },
    getAdditionalAssessments() {
      this.dialog = false;
      this.loading = false;
      additionalAssessment
          .paginate(this.course_id, this.search)
          .then(response => {
              this.additionalAssessments = response.data.data;
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading = false;

          })
    },

    addAdditionalAssessments() {
      this.$refs["additional-assessment"].showModal();
    },
    editAdditionalAssessments(additionalAssessment) {
      this.$refs["additional-assessment"].setAssessment(additionalAssessment);
    },

    deleteAssessment(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            additionalAssessment.delete(this.course_id, id).then(response => {
              this.$snotify.success('Information deleted')
              this.getAdditionalAssessments();
            });
          }
        }
      });
    },
    openDialog() {
      this.$refs["additional-assessment"].createNewAdditionalAssessment();
    },
    getCourse() {
      courseService.show(this.course_id).then(response => {
        this.course = response.data.courses;
        this.getProgram(this.course.program_id)
      }).catch(error => {
        this.$snotify.error('Something went wrong')
      });
    },
    getProgram(programId) {
      programService.show(programId).then(response => {
        this.program = response.data.program;
      }).catch(error => {
        this.$snotify.error('Something went wrong')
      });
    },
    resetFilter(){
      this.search={}
      this.getAdditionalAssessments();
    }
  }
};
</script>
