<template>
  <v-app>
    <v-dialog
        v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"}   additional assessment`}}

            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="resetForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
          <div class="row">

            <div class="col-12">
              <v-select
                  v-model="additional_assessment.title"
                  outlined
                  :items="program.additional_assessments"
                  dense
                  label="Title" @change="setMarks">

              </v-select>
              <span class="text-danger"
                    v-if="$v.additional_assessment.title.$error">This information is required</span>

            </div>
            <div class="col-12">
              <v-text-field
                  type="number"
                  min="1"
                  v-model="additional_assessment.mark"
                  outlined
                  dense
                  label="Mark">

              </v-text-field>
              <span class="text-danger"
                    v-if="$v.additional_assessment.mark.$error">This information is required</span>
            </div>

            <div class="col-12">
              <v-text-field
                  type="number"
                  min="1"
                  v-model="additional_assessment.pass_mark"
                  outlined
                  dense
                  label="Pass mark">

              </v-text-field>
              <span class="text-danger"
                    v-if="$v.additional_assessment.mark.$error">This information is required</span>
            </div>
            <div class="col-12">
              <v-select dense outlined v-model="additional_assessment.academic_year_id"
                        :items="academicYears" item-text="title" item-value="id"
                        label="Academic year"></v-select>
            </div>
            <div class="col-6">
              Impacts result
              <v-switch  :label="additional_assessment.is_active?'Yes':'No'" v-model="additional_assessment.impacts_result"></v-switch>
            </div>

            <div class="col-6">
              Status
              <v-switch  :label="additional_assessment.is_active?'Active':'Inactive'" v-model="additional_assessment.is_active"></v-switch>
            </div>
<!--            <div class="col-12 text-right mt-4">
              <v-btn class="btn btn-standard" @click="resetForm">Cancel</v-btn>
              <v-btn class="ml-2 btn btn-primary text-white" depressed :loading="isBusy" @click="createAndUpdate">Save
              </v-btn>
            </div>-->
          </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-standard cancel-btn"
              depressed
              @click="resetForm">
            Cancel
          </v-btn>
          <v-btn
              class="text-white ml-2 btn btn-primary"
              depressed
              :loading="isBusy"
              @click="createAndUpdate()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AdditionalAssessment from "@/core/services/additionalAssessment/AdditionalAssessmentService";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";

const academicYearService = new AcademicYearService();
const additionalAssessment = new AdditionalAssessment();
const siteSettingService = new SiteSettingService();

export default {
  props: ['course_id', 'program'],
  name: "additional_assessment-create-and-update",
  validations: {
    additional_assessment: {
      title: {required},
      mark: {required},
    }
  },
  data() {
    return {
      menu: false,
      isBusy: false,
      menu2: false,
      edit: false,
      dialog: false,
      levels: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      selectedAcademicYears: [],
      setting: null,
      additional_assessment: {
        title: null,
        mark: null,
        pass_mark: null,
        course_id: null,
        applicable_academic_years: null,
        program_id: null,
        impacts_result: 1,
        is_active: 1,
      }
    };
  },
  computed: {
    programId() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    academicYearsProcessed() {
      return this.selectedAcademicYears ? this.selectedAcademicYears.join() : '';
    }
  },

  mounted() {
    this.getAcademicYears()
    this.getSetting()
  },
  methods: {
    getSetting() {
      siteSettingService.show().then(response => {
        this.setting = response.data;
        if (this.setting && this.setting.id) {
        }
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;
      });
    },

    showModal() {
      this.dialog = true;
    },
    createNewAdditionalAssessment(){
      this.dialog = true;
      this.edit=false;
      this.additional_assessment= {
            title: null,
            mark: null,
            course_id: null,
            applicable_academic_years: null,
            program_id: null,
            is_active: 1,
      }
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.dialog = false;
    },
    createAndUpdate() {
      // this.additional_assessment.applicable_academic_years = this.setting.applicable_academic_years;
      this.additional_assessment.program_id = this.programId;

      this.$v.$touch();
      if (this.$v.additional_assessment.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.additional_assessment.course_id = this.course_id;
        if (this.additional_assessment.id) {
          this.updateAdditionalAssessment();
        } else {
          this.createAdditionalAssessment();
        }
      }
    },
    createAdditionalAssessment() {
      this.isBusy = true;
      this.additional_assessment.applicable_academic_years = this.academicYearsProcessed;
      additionalAssessment.store(this.course_id, this.additional_assessment).then(response => {
        console.log(response.data.status)
        if (!response.data.status) {
          this.$snotify.error(response.data.msg);
        } else {
          this.resetForm()
          this.isBusy = false;
          this.$snotify.success("Information added");
        }
      }).catch(error => {
        this.$snotify.error('Something went wrong')
      }).finally(() => {
        this.isBusy = false
      });
    },
    updateAdditionalAssessment() {
      this.isBusy = true;
      this.additional_assessment.applicable_academic_years = this.academicYearsProcessed;
      additionalAssessment.update(this.course_id, this.additional_assessment.id, this.additional_assessment).then(response => {
        this.resetForm()
        this.isBusy = false;
        this.$snotify.success("Information updated");
      }).catch(error => {
        this.$snotify.error('Something went wrong')
      }).finally(() => {
        this.isBusy = false
      });
    },
    resetForm() {
      this.additional_assessment = {
        title: null,
        mark: null,
        applicable_academic_years: null,
        program_id: null,
        is_active: 1,
      }
      this.selectedAcademicYears = null;
      this.edit = false
      this.dialog = false
      this.$v.$reset()
      this.$emit("refresh");
    },
    setAssessment(assessment) {
      this.dialog = true;
      this.edit=true;
      this.additional_assessment = assessment;
    },
    setMarks() {
      this.additional_assessment.mark = 0
      let title = this.additional_assessment.title;
      if (title) {
        if (title.indexOf("-") > 0) {
          let data = title.split('-')
          this.additional_assessment.mark = data[1]
        }
      }
    }

  },
};
</script>
